import React, { Component } from 'react';
import { connect } from 'react-redux';

class PopularPost extends Component {
    render() {
        const { img, title, author, date } = this.props.data;
        return (
            <article className='col-block popular__post'>
                <a href='#0' className='popular__thumb'>
                    <img src={require(`${img.src}`)} alt='' />
                </a>
                <h5>
                    <a href='#0'>{title}</a>
                </h5>
                <section className='popular__meta'>
                    <span className='popular__author'>
                        <span>{'By '}</span> <a href='#0'>{author}</a>
                    </span>
                    <span className='popular__date'>
                        <span>{' on '}</span>
                        <time dateTime='2017-12-19'>{date}</time>
                    </span>
                </section>
            </article>
        );
    }
}

const mapStateToProps = (state, passed) => {
    return {
        data: state.content.popularPost[passed.id]
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PopularPost);
