import React, { Component } from 'react';
import { connect } from 'react-redux';

class FooterCopyright extends Component {
    render() {
        const { copyright, backlink } = this.props.data;
        return (
            <div className='s-footer__copyright'>
                <span>{copyright}</span>
                <span>
                    {backlink.message + ' '}
                    <a href={backlink.link} rel='nofollow'>{backlink.website}</a>
                </span>
            </div>
        );
    }
}

const mapStateToProps = (state, passed) => {
    return {
        data: state.content.footerCopyright[passed.id]
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FooterCopyright);
