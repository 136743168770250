import React, { Component } from 'react';
import { connect } from 'react-redux';

class BottomTags extends Component {
    render() {
        const { title, tags } = this.props.data;
        return (
            <div className='col-full tags'>
                <h3>{title}</h3>

                <div className='tagcloud'>
                    {tags.map((tag, i) => (
                        <a href={tag.link} key={i}>{tag.name}</a>
                    ))}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, passed) => {
    return {
        data: state.content.bottomTags[passed.id]
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BottomTags);
