import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

class HelmetMeta extends Component {
    render() {
        // DO NOT DECONSTRUCT THE PROPS, JUST DONT !!!
        // default value for metatags
        const title = this.props.title
            ? this.props.title
            : 'Pranav Goel\'s Blog Home';
        const description = this.props.description
            ? this.props.description
            : 'Digital Checks are revolutionizing the way individuals and businesses receive and send ACH payments - No app, no sign-up, no hidden fees.';
        const site_name = this.props.site_name
            ? this.props.site_name
            : 'Checkbook - Simple payments for every occasion';
        const keywords = this.props.keywords
            ? this.props.keywords
            : 'ACH,ACH payment, Digital Checks, echecks, e-checks';
        const url = this.props.url ? this.props.url : 'https://checkbook.io';
        const verify = this.props.verify ? this.props.verify : '';
        const verifyname = this.props.verifyname ? this.props.verifyname : '';

        return (
            <Helmet>
                <title>{title}</title>
                <meta name='description' content={description} />
                <meta name='keywords' content={keywords} />

                <meta name={verifyname} content={verify} />

                {/* open graph meta tags */}
                <meta property='og:site_name' content={site_name} />
                <meta property='og:title' content={title} />
                <meta property='og:description' content={description} />
                <meta property='og:url' content={url} />
                <meta property='og:type' content='website' />

                {/* Twitter cards tags */}
                <meta name='twitter:card' content='summary_large_image' />
                <meta name='twitter:title' content={title} />
                <meta name='twitter:description' content={description} />
            </Helmet>
        );
    }
}

export default HelmetMeta;
