import React, { Component } from 'react';
import { connect } from 'react-redux';
import mapObjectToComponent from 'utils/mapComponent';
// import Logo from 'store/components/logo';
// import HeaderSocial from 'store/components/headerSocial';
// import Navbar from 'store/components/navbar';

class SExtra extends Component {
    render() {
        // const { logo, headerSocial, headerSearch, navbar } = this.props.data;
        return (
            <section className='s-extra'>
                {this.props.data.instances.map(mapObjectToComponent)}
            </section>
        );
    }
}

const mapStateToProps = (state, passed) => {
    return {
        data: state.content.sExtra[passed.id]
    };
};

export default connect(mapStateToProps)(SExtra);
