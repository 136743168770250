import React, { Component } from 'react';
import { connect } from 'react-redux';

class Category extends Component {
    render() {
        const { categoryName, description } = this.props.data;
        console.log(this.data);
        return (
            <div class='row narrow'>
                <div class='col-full s-content__header' data-aos='fade-up'>
                    <h1>{categoryName}</h1>
                    <p class='lead'>{description}</p>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, passed) => {
    return {
        data: state.content.category[passed.id]
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Category);
