import React, { Component } from 'react';
import { connect } from 'react-redux';

class HeaderSearch extends Component {
    constructor(props) {
        super(props);
        this.state = { search: '' };
        this.setSearchWrap = (element) => {
            this.searchWrap = element
        }
        this.setCloseSearch = (element) => {
            this.closeSearch = element;
        }
        this.setSearchField = element => {
            this.searchField = element;
        };
    }

    addSearchClass = e => {
        e.preventDefault();
        e.stopPropagation();
        document.body.classList.add('search-is-visible');
        this.searchField.focus();
    };

    removeSearchClass = e => {
        e.stopPropagation();
        if (document.body.classList.contains('search-is-visible')) {
            document.body.classList.remove('search-is-visible');
            this.searchField.blur();
        }
    };

    searchWrapTrigger = e => {
        if (!e.target.matches('.search-field')) {
            this.closeSearch.click();
        }
    };

    searchFieldTrigger = e => {
        e.stopPropagation();
    };

    render() {
        const { label, close } = this.props.data;
        const Aux = props => props.children;
        return (
            <Aux>
                {/* eslint-disable-next-line */}
                <a
                    className={'header__search-trigger'}
                    href='#0'
                />
                <div className='header__search' ref={this.setSearchWrap} onClick={this.searchWrapTrigger}>
                    <form
                        role='search'
                        method='get'
                        className='header__search-form'
                        action='#'>
                        <label>
                            <span className='hide-content'>{label}</span>
                            <input
                                type='search'
                                className='search-field'
                                placeholder='Type Keywords'
                                name='s'
                                title='Search for:'
                                autoComplete='off'
                                ref={this.setSearchField}
                                onClick={this.searchFieldTrigger}
                            />
                        </label>
                        <input
                            type='submit'
                            className='search-submit'
                            value='Search'
                        />
                    </form>

                    <a
                        href='#0'
                        title='Close Search'
                        className='header__overlay-close'
                        ref={this.setCloseSearch}
                        onClick={this.removeSearchClass}>
                        {close}
                    </a>
                </div>
                {/* end header__search */}
                
            </Aux>
        );
    }
}

const mapStateToProps = (state, passed) => {
    return {
        data: state.content.headerSearch[passed.id]
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HeaderSearch);
