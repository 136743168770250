import React, { Component } from 'react';
import { connect } from 'react-redux';
import mapObjectToComponent from 'utils/mapComponent';

// import FeaturedBig from 'store/components/featuredBig';
// import FeaturedSmall from 'store/components/featuredSmall';

class PageHeaderContent extends Component {
    render() {
        return (
            <div className='pageheader-content row'>
                <div className='col-full'>
                    <div className='featured'>
                        {this.props.data.instances.map(mapObjectToComponent)}
                        {/* <FeaturedBig
                            featuredBig={pageHeaderContent.featuredBig}
                        />
                        <FeaturedSmall
                            featuredSmall={pageHeaderContent.featuredSmall}
                        /> */}
                    </div>
                    {/* end featured */}
                </div>
                {/* end col-full */}
                {/* end pageheader-content row */}
            </div>
        );
    }
}

const mapStateToProps = (state, passed) => {
    return {
        data: state.content.pageHeaderContent[passed.id]
    };
};

export default connect(mapStateToProps)(PageHeaderContent);
