import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';

class BlogContent extends Component {
    // constructor() {
    //     super();
    //     this.state = {
    //         tagName: 'h1'
    //     };
    // }

    // sizeChange(i) {
    //     this.setState({
    //         tagName: i
    //     });
    // }
    handleContent = (cont, i) => {
        const id = cont.id;
        const content = cont.content;
        const key = i;
        if (
            id === 'h2' ||
            id === 'h3' ||
            id === 'h4' ||
            id === 'h5' ||
            id === 'h6'
        ) {
            const Id = id;
            return <Id key={key}>{content}</Id>;
        } else if (id === 'paragraph') {
            return (
                <p key={key}>
                    {content.map((build, j) => this.handleContent(build, j))}
                </p>
            );
        } else if (id === 'pAndImg') {
            console.log('pAndImage: ' + content.result.paragraph);
            return (
                <p key={key}>
                    <a href='#0'>
                        <img
                            width='120'
                            height='120'
                            className='pull-left'
                            alt={content.img.alt}
                            src={require(`${content.img.src}`)}
                        />
                    </a>
                    {content.result.paragraph + ' '}
                </p>
            );
        } else if (id === 'link') {
            return <a href={content.link} key={key}>{content.value}</a>;
        } else if (id === 'abbr') {
            return <abbr title={content.title} key={key}>{content.value}</abbr>;
        } else if (id === 'bold') {
            return <strong key={key}>{content}</strong>;
        } else if (id === 'italics') {
            return <em key={key}>{content}</em>;
        } else if (id === 'strikethrough') {
            return <del key={key}>{content}</del>;
        } else if (id === 'highlight') {
            return <mark key={key}>{content}</mark>;
        } else if (id === 'code') {
            return <code key={key}>{content}</code>;
        } else if (id === 'dropCaps') {
            return <p className='drop-cap' key={key}>{content}</p>;
        } else if (id === 'small') {
            return <small key={key}>{content}</small>;
        } else if (id === 'pullQuote') {
            return (
                <aside className='pull-quote'>
                    <blockquote>
                        <p>{content}</p>
                    </blockquote>
                </aside>
            );
        } else if (id === 'blockQuote') {
            return (
                <blockquote>
                    <p>{content.quote}</p>
                    <cite>{content.author}</cite>
                </blockquote>
            );
        } else if (id === 'blockQuoteCite') {
            return (
                <blockquote cite={content.cite}>
                    <p>{content.quote}</p>
                    <cite>{content.author}</cite>
                </blockquote>
            );
        } else if (id === 'orderedList') {
            return (
                <ol key={key}>
                    {content.map((element, j) =>
                        this.handleContent(element, j)
                    )}
                </ol>
            );
        } else if (id === 'list') {
            return (
                <li key={key}>
                    {content.map((element, j) =>
                        this.handleContent(element, j)
                    )}
                </li>
            );
        } else if (id === 'unorderedList') {
            return (
                <ul key={key}>
                    {content.map((element, j) =>
                        this.handleContent(element, j)
                    )}
                </ul>
            );
        } else if (id === 'unorderedListDisc') {
            return (
                <ul className='disc' key={key}>
                    {content.map((element, j) =>
                        this.handleContent(element, j)
                    )}
                </ul>
            );
        } else if (id === 'definition') {
            return (
                <dl key={key}>
                    <dt>
                        <strong>{content.term}</strong>
                    </dt>
                    {content.definition.map((def, j) => (
                        <dd key={j}>
                            {def.content.map((style, k) =>
                                this.handleContent(style, k)
                            )}
                        </dd>
                    ))}
                </dl>
            );
        } else if (id === 'sup') {
            return <sup>{content}</sup>;
        } else if (id === 'stats') {
            content.map((stats, j) => (
                <li key={j}>
                    <a href='#0'>
                        {stats.stat} <em>{stats.label}</em>
                    </a>
                </li>
            ));
        } else if (id === 'table') {
            return (
                <table>
                    <thead>
                        <tr>
                            {content.headers.map((head, j) => (
                                <th key={j}>head</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {content.rows.map((row, j) => (
                            <tr key={j}>
                                {row.map((data, k) => (
                                    <td key={k}>{data}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            );
        } else {
            return `${content}`;
        }
    };
    render() {
        const { content } = this.props;
        // <>{content.map((section, i) => this.handleContent(section, i))}</>
        return (
            <>{ReactHtmlParser(content)}</>
        );
    }
}

export default BlogContent;
