import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class LinkInternal extends Component {
    render() {
        const { to, className, value } = this.props;

        if (!to.startsWith('http')) {
            return (
                <Link to={to} className={className}>
                    {value}
                </Link>
            );
        } else {
            return (
                <a href={to} className={className}>
                    {value}
                </a>
            );
        }
    }
}

export default LinkInternal;
