// import { isAbsolute } from 'path';

var initialState = {
    dynamicPage: {
        root: {
            instances: [
                { component: 'pageHeader', id: 'root' },
                { component: 'sContent', id: 'root' },
                { component: 'sExtra', id: 'root' },
                { component: 'footer', id: 'root' }
                // { component: 'preloader', id: 'root' }
            ],
            url: '/',
            metaTags: {}
        },
        blog1: {
            instances: [
                { component: 'pageHeader', id: 'blog' },
                { component: 'blogStandard', id: 'storytimeSv' },
                { component: 'sExtra', id: 'root' },
                { component: 'footer', id: 'root' }
                // { component: 'preloader', id: 'root' }
            ],
            url: '/storytime-interning-in-silicon-valley',
            metaTags: {}
        },
        personal: {
            instances: [
                { component: 'pageHeader', id: 'category' },
                { component: 'sContent', id: 'personal' },
                { component: 'sExtra', id: 'root' },
                { component: 'footer', id: 'root' }
                // { component: 'preloader', id: 'root' }
            ],
            url: '/personal',
            metaTags: {}
        },
        work: {
            instances: [
                { component: 'pageHeader', id: 'category' },
                { component: 'sContent', id: 'work' },
                { component: 'sExtra', id: 'root' },
                { component: 'footer', id: 'root' }
                // { component: 'preloader', id: 'root' }
            ],
            url: '/work',
            metaTags: {}
        },
        travel: {
            instances: [
                { component: 'pageHeader', id: 'category' },
                { component: 'sContent', id: 'travel' },
                { component: 'sExtra', id: 'root' },
                { component: 'footer', id: 'root' }
                // { component: 'preloader', id: 'root' }
            ],
            url: '/travel',
            metaTags: {}
        },
        technology: {
            instances: [
                { component: 'pageHeader', id: 'category' },
                { component: 'sContent', id: 'technology' },
                { component: 'sExtra', id: 'root' },
                { component: 'footer', id: 'root' }
                // { component: 'preloader', id: 'root' }
            ],
            url: '/technology',
            metaTags: {}
        },
    },
    pageHeader: {
        root: {
            instances: [
                { component: 'header', id: 'root' },
                { component: 'pageHeaderContent', id: 'root' }
            ]
        },
        blog: {
            instances: [{ component: 'header', id: 'root' }]
        },
        category: {
            instances: [{ component: 'header', id: 'root' }]
        }
    },
    pageHeaderContent: {
        root: {
            instances: [
                { component: 'featuredBig', id: 'root' },
                { component: 'featuredSmall', id: 'root' }
            ]
        }
    },
    header: {
        root: {
            instances: [
                { component: 'logo', id: 'root' },
                { component: 'headerSocial', id: 'root' },
                { component: 'headerSearch', id: 'root' },
                { component: 'navbar', id: 'root' }
            ]
        }
    },
    headerSearch: {
        root: {
            label: 'Search for:',
            close: 'Close'
        }
    },
    logo: {
        root: {
            link: '/',
            img: {
                src: './images/pg.svg',
                alt: 'Homepage'
            }
        }
    },
    headerSocial: {
        root: [
            { link: '/', class: 'fa fa-facebook' },
            { link: '/', class: 'fa fa-twitter' },
            { link: '/', class: 'fa fa-instagram' },
            { link: '/', class: 'fa fa-pinterest' }
        ]
    },
    navbar: {
        root: {
            toggle: 'Menu',
            heading: 'Site Navigation',
            nav: [
                { title: 'Home', link: '/' },
                {
                    title: 'Categories',
                    dropdown: [
                        { title: 'Work', link: '/Work' },
                        { title: 'Personal', link: '/Personal' },
                        { title: 'Travel', link: '/travel' },
                        { title: 'Technology', link: '/technology' }
                    ]
                },
                // { title: 'Resume', link: '/' },
                { title: 'About', link: '/' }
                // { title: 'Contact', link: '/' }
            ]
        }
    },
    featuredBig: {
        root: [
            {
                bgimg: './images/silicon-valley/camera-coffee-composition.jpg',
                category: 'Work',
                categoryLink: '/work',
                title: 'Interning in Silicon Valley',
                titleLink: '/storytime-interning-in-silicon-valley',
                entryInfo: {
                    link: '/',
                    img: {
                        src: './images/avatars/user-03.jpg',
                        alt: 'avatar'
                    }
                },
                entryMeta: {
                    link: '/',
                    author: 'Pranav Goel',
                    date: 'August 4, 2019'
                }
            }
        ]
    },
    featuredSmall: {
        root: [
            {
                bgimg: './images/silicon-valley/camera-coffee-composition.jpg',
                category: 'Management',
                categoryLink: '/',
                title: 'The Pomodoro Technique Really Works.',
                titleLink: '/',
                entryInfo: {
                    link: '/',
                    img: {
                        src: './images/avatars/user-03.jpg',
                        alt: 'avatar'
                    }
                },
                entryMeta: {
                    link: '/',
                    author: 'Pranav Goel',
                    date: 'July 22, 2019'
                }
            },
            {
                bgimg: './images/silicon-valley/camera-coffee-composition.jpg',
                category: 'Lifestyle',
                categoryLink: '/',
                title: 'Throwback To The Good Old Days.',
                titleLink: '/',
                entryInfo: {
                    link: '/',
                    img: {
                        src: './images/avatars/user-03.jpg',
                        alt: 'avatar'
                    }
                },
                entryMeta: {
                    link: '/',
                    author: 'Pranav Goel',
                    date: 'July 22, 2019'
                }
            }
        ]
    },
    sContent: {
        root: {
            instances: [
                { component: 'articles', id: 'root' }
                // { component: 'videoBrick', id: 'article1' },
                // { component: 'quoteBrick', id: 'article1' },
                // // { component: 'galleryBrick', id: 'article1' },
                // { component: 'audioBrick', id: 'article1' },
                // { component: 'quoteBrick', id: 'article1' },
                // { component: 'linkBrick', id: 'article1' },
                // { component: 'standardBrick', id: 'article1' },
                // { component: 'videoBrick', id: 'article1' }
            ]
        },
        personal: {
            instances: [
                { component: 'category', id: 'personal' },
                { component: 'articles', id: 'personal' }
            ]
        },
        work: {
            instances: [
                { component: 'category', id: 'work' },
                { component: 'articles', id: 'work' }
            ]
        },
        travel: {
            instances: [
                { component: 'category', id: 'travel' },
                { component: 'articles', id: 'travel' }
            ]
        },
        technology: {
            instances: [
                { component: 'category', id: 'technology' },
                { component: 'articles', id: 'technology' }
            ]
        }
    },
    category: {
        personal: {
            categoryName: 'Category: Personal',
            description:
                'This category is for all the articles related to my personal experiences. There are no current articles under this category.'
        },
        work: {
            categoryName: 'Category: Work',
            description:
                'This category is for all the articles related to my work experiences.'
        },
        travel: {
            categoryName: 'Category: Travel',
            description:
                'This category is for all the articles related to my travel experiences. There are no current articles under this category.'
        },
        technology: {
            categoryName: 'Category: Technology',
            description:
                'This category is for all the articles related to my technology experiences. There are no current articles under this category.'
        }
    },
    articles: {
        root: {
            entries: [
                { component: 'standardBrick', id: 'article1' }
                // { component: 'videoBrick', id: 'article1' },
                // { component: 'quoteBrick', id: 'article1' },
                // // { component: 'galleryBrick', id: 'article1' },
                // { component: 'audioBrick', id: 'article1' },
                // { component: 'quoteBrick', id: 'article1' },
                // { component: 'linkBrick', id: 'article1' },
                // { component: 'standardBrick', id: 'article1' },
                // { component: 'videoBrick', id: 'article1' }
            ]
        },
        personal: {
            entries: []
        },
        work: {
            entries: [{ component: 'standardBrick', id: 'article1' }]
        },
        travel: {
            entries: []
        },
        technology: {
            entries: []
        }
    },
    standardBrick: {
        article1: {
            content: {
                blogLink: '/storytime-interning-in-silicon-valley',
                img: {
                    src:
                        './images/silicon-valley/camera-coffee-composition.jpg',
                    srcSet: [
                        {
                            srcs:
                                './images/silicon-valley/camera-coffee-composition-400.jpg',
                            attr: '1x'
                        },
                        {
                            srcs:
                                './images/silicon-valley/camera-coffee-composition-800.jpg',
                            attr: '2x'
                        }
                    ],
                    alt: 'Silicon Valley Fintech'
                },
                date: 'August 4, 2019',
                title: 'Interning in Silicon Valley',
                summary:
                    'Being exposed to the Fintech Industry was a unique experience that I will never forget. Read more about my experiences in this article...',
                categories: [{ category: 'Work', link: '/work' }],
            }
        },
    },
    videoBrick: {
        article1: {
            article: 'Video',
            content: {
                blogLink: '/',
                video: {
                    link:
                        'https://player.vimeo.com/video/117310401?color=01aef0&title=0&byline=0&portrait=0',
                    img: {
                        src: './images/shutterbug-400.jpg',
                        srcSet: [
                            './images/thumbs/masonry/shutterbug-400.jpg 1x',
                            './images/thumbs/masonry/shutterbug-800.jpg 2x'
                        ],
                        alt: 'article video'
                    }
                },
                date: 'July 22, 2019',
                title: 'Key Benefits Of Being Pranav',
                summary:
                    'Lorem ipsum Sed eiusmod esse aliqua sed incididunt aliqua incididunt mollit id et sit proident dolor nulla sed commodo est ad minim elit reprehenderit nisi officia aute incididunt velit sint in aliqua...',
                categories: [
                    { category: 'Family', link: '/' },
                    { category: 'Photography', link: '/' }
                ]
            }
        }
    },
    quoteBrick: {
        article1: {
            article: 'Quote',
            content: {
                quote:
                    'Pranav is the greatest person in the entire fucking world. You better believe it!',
                cite: 'Naruto Uzumaki'
            }
        }
    },
    galleryBrick: {
        article1: {
            article: 'Gallery',
            content: {
                galleryPics: [
                    {
                        src: './gallery/gallery-1-400.jpg',
                        srcSet: [
                            './images/thumbs/masonry/gallery/gallery-1-400.jpg 1x',
                            './images/thumbs/masonry/gallery/gallery-1-800.jpg 2x'
                        ],
                        alt: 'article gallery'
                    },
                    {
                        src: './gallery/gallery-2-400.jpg',
                        srcSet: [
                            './images/thumbs/masonry/gallery/gallery-2-400.jpg 1x',
                            './images/thumbs/masonry/gallery/gallery-2-800.jpg 2x'
                        ],
                        alt: 'article gallery'
                    },
                    {
                        src: './gallery/gallery-3-400.jpg',
                        srcSet: [
                            './images/thumbs/masonry/gallery/gallery-3-400.jpg 1x',
                            './images/thumbs/masonry/gallery/gallery-3-800.jpg 2x'
                        ],
                        alt: 'article gallery'
                    }
                ],
                date: 'July 22, 2019',
                title:
                    'Pranav Outclasses You by the Absolute Fucking Biggest Margin',
                summary:
                    'Lorem ipsum Sed eiusmod esse aliqua sed incididunt aliqua incididunt mollit id et sit proident dolor nulla sed commodo est ad minim elit reprehenderit nisi officia aute incididunt velit sint in aliqua...',
                categories: [
                    { category: 'Work', link: '/' },
                    { category: 'Management', link: '/' }
                ]
            }
        }
    },
    audioBrick: {
        article1: {
            article: 'Audio',
            content: {
                blogLink: '/',
                img: {
                    src: './images/shutterbug-400.jpg',
                    srcSet: [
                        './images/thumbs/masonry/shutterbug-400.jpg 1x',
                        './images/thumbs/masonry/shutterbug-800.jpg 2x'
                    ],
                    alt: 'article video'
                },
                date: 'July 22, 2019',
                title:
                    'Pranav Outclasses You by the Absolute Fucking Biggest Margin',
                summary:
                    'Lorem ipsum Sed eiusmod esse aliqua sed incididunt aliqua incididunt mollit id et sit proident dolor nulla sed commodo est ad minim elit reprehenderit nisi officia aute incididunt velit sint in aliqua...',
                categories: [
                    { category: 'Work', link: '/' },
                    { category: 'Management', link: '/' }
                ],
                audio: {
                    id: 'player_html5',
                    src: './media/AirReview-Landmarks-02-ChasingCorporate.mp3',
                    width: '100%',
                    height: '42',
                    controls: 'controls'
                }
            }
        }
    },
    linkBrick: {
        article1: {
            article: 'Link',
            content: {
                text:
                    'The Only Resource You Will Need To Start a Blog Using WordPress.',
                link: 'https://colorlib.com/',
                display: 'https://colorlib.com/'
            }
        }
    },
    sExtra: {
        root: {
            instances: [
                { component: 'topRow', id: 'root' },
                { component: 'bottomRow', id: 'root' }
            ]
        }
    },
    topRow: {
        root: {
            instances: [
                { component: 'eightColumnPopular', id: 'root' },
                { component: 'aboutSnippet', id: 'root' }
            ]
        }
    },
    bottomRow: {
        root: {
            instances: [{ component: 'bottomTags', id: 'root' }]
        }
    },
    eightColumnPopular: {
        root: {
            title: 'Popular Posts',
            instances: [
                { component: 'popularPost', id: 'box1' },
                // { component: 'popularPost', id: 'box1' },
                // { component: 'popularPost', id: 'box1' },
                // { component: 'popularPost', id: 'box1' },
                // { component: 'popularPost', id: 'box1' },
                // { component: 'popularPost', id: 'box1' }
            ]
        }
    },
    popularPost: {
        box1: {
            img: {
                src: './images/camera-coffee-composition-150.jpg',
                alt: 'Wheel Image Small'
            },
            title: 'Interning in Silicon Valley',
            author: 'Pranav Goel',
            date: 'August 4, 2019'
        }
    },
    aboutSnippet: {
        root: {
            title: 'About A Fresh Inquiry',
            summary:
                'A Fresh Inquiry is a blog run by Pranav Goel. Here you will find content on Technology, Personal Experiences, Travel, Work, and more! I love getting to know new people and learning new things about the world every day. Talk to me on any of my social media below!',
            social: [
                { classSocial: 'fa-facebook', link: '#0' },
                { classSocial: 'fa-twitter', link: '#0' },
                { classSocial: 'fa-instagram', link: '#0' },
                { classSocial: 'fa-pinterest', link: '#0' }
            ]
        }
    },
    bottomTags: {
        root: {
            title: 'Categories',
            tags: [
                { link: '#0', name: 'Work' },
                { link: '#0', name: 'Personal' },
                { link: '#0', name: 'Technology' },
                { link: '#0', name: 'Travel' },
                // { link: '#0', name: 'Recipe' },
                // { link: '#0', name: 'Places' },
                // { link: '#0', name: 'Tips' },
                // { link: '#0', name: 'Friends' },
                // { link: '#0', name: 'Travel' },
                // { link: '#0', name: 'Exercise' },
                // { link: '#0', name: 'Reading' },
                // { link: '#0', name: 'Running' },
                // { link: '#0', name: 'Self-Help' },
                // { link: '#0', name: 'Vacation' },
                // { link: '#0', name: 'Music' }
            ]
        }
    },
    footer: {
        root: {
            instances: [
                { component: 'footerTop', id: 'root' },
                { component: 'footerBottom', id: 'root' }
            ]
        }
    },
    footerTop: {
        root: {
            instances: [
                { component: 'footerLinks', id: 'links' },
                { component: 'footerLinks', id: 'archives' },
                { component: 'footerLinks', id: 'social' },
                { component: 'footerSubscribe', id: 'root' }
            ]
        }
    },
    footerLinks: {
        links: {
            title: 'Quick Links',
            classID: 'sitelinks',
            linkList: [
                { link: '#0', name: 'Home' },
                { link: '#0', name: 'Blog' },
                // { link: '#0', name: 'Styles' },
                { link: '#0', name: 'About' },
                { link: '#0', name: 'Contact' },
                // { link: '#0', name: 'Privacy Policy' }
            ]
        },
        archives: {
            title: 'Archives',
            classID: 'archives',
            linkList: [
                // { link: '#0', name: 'August 2019' },
                // { link: '#0', name: 'July 2019' },
                // { link: '#0', name: 'June 2019' },
                // { link: '#0', name: 'May 2019' },
                // { link: '#0', name: 'April 2019' },
                // { link: '#0', name: 'March 2019' }
                { link: '#0', name: 'Archives Unavailable'}
            ]
        },
        social: {
            title: 'Social',
            classID: 'social',
            linkList: [
                { link: '#0', name: 'Facebook' },
                { link: '#0', name: 'Instagram' },
                { link: '#0', name: 'Twitter' },
                { link: '#0', name: 'Linked In' }
            ]
        }
    },
    footerSubscribe: {
        root: {
            title: 'My Newsletter',
            message:
                'If you enjoy the content on this blog, please subscribe to my newsletter!'
        }
    },
    footerBottom: {
        root: {
            instances: [
                { component: 'footerCopyright', id: 'root' },
                { component: 'footerGoToTop', id: 'root' }
            ]
        }
    },
    footerCopyright: {
        root: {
            copyright: '© Copyright Pranav Goel 2019',
            backlink: {
                message: 'Visit my',
                link: 'http://pranavgoel.web.illinois.edu/',
                website: 'Personal Website'
                // message: 'Site Template by',
                // link: 'https://colorlib.com/',
                // website: 'Colorlib'
            }
        }
    },
    footerGoToTop: {
        root: {}
    },
    blogStandard: {
        storytimeSv: {
            title: 'Interning in Silicon Valley',
            date: 'August 4, 2019',
            categories: [{ link: '#0', tag: 'Personal' }],
            img: {
                src: './images/silicon-valley/camera-coffee-composition.jpg',
                srcSet: [
                    {
                        src:
                            './images/silicon-valley/camera-coffee-composition-2000.jpg',
                        attr: '2000w'
                    },
                    {
                        src:
                            './images/silicon-valley/camera-coffee-composition-1000.jpg',
                        attr: '1000w'
                    },
                    {
                        src:
                            './images/silicon-valley/camera-coffee-composition-500.jpg',
                        attr: '500w'
                    }
                ],
                sizes: '(max-width: 2000px) 100vw, 2000px',
                alt: 'Silicon Valley',
                description:
                    'Being exposed to the Fintech Industry was a unique experience that I will never forget.'
            },
            content:
                '<h2>The Startup</h2><p class="drop-cap">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove right at the coast of the Semantics, a large language ocean. A small river named Duden flows by their place and supplies it with the necessary regelialia. Morbi tincidunt, orci ac convallis aliquam, lectus turpis varius lorem, eu	posuere nunc justo tempus leo. Donec mattis, purus nec placerat bibendum, dui pede condimentum odio, ac blandit ante orci ut diam. Cras fringilla magna. Phasellus suscipit, leo a pharetra condimentum, lorem tellus eleifend magna, eget fringilla velit magna id neque.</p>'
            // content: [
            //     { id: 'h2', content: 'The Startup' },
            //     {
            //         id: 'dropCaps',
            //         content:
            //             'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec libero. Suspendisse bibendum. Cras id urna. Morbi tincidunt, orci ac convallis aliquam, lectus turpis varius lorem, eu posuere nunc justo tempus leo. Donec mattis, purus nec placerat bibendum, dui pede condimentum odio, ac blandit ante orci ut diam. Cras fringilla magna. Phasellus suscipit, leo a pharetra condimentum, lorem tellus eleifend magna, eget fringilla velit magna id neque posuere nunc justo tempus leo.'
            //     },
            //     {
            //         id: 'paragraph',
            //         content: [
            //             {
            //                 id: '',
            //                 content:
            //                     'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec libero. Suspendisse bibendum. Cras id urna. Morbi tincidunt, orci ac convallis aliquam, lectus turpis varius lorem, eu posuere nunc justo tempus leo. Donec mattis, purus nec placerat bibendum, dui pede condimentumodio, ac blandit ante orci ut diam.'
            //             }
            //         ]
            //     },
            //     {
            //         id: 'orderedList',
            //         content: [
            //             {
            //                 id: 'list',
            //                 content: [{ id: '', content: 'I like Pie' }]
            //             },
            //             {
            //                 id: 'list',
            //                 content: [
            //                     {
            //                         id: 'unorderedList',
            //                         content: [
            //                             {
            //                                 id: 'list',
            //                                 content: [
            //                                     { id: '', content: 'Pie BOY' }
            //                                 ]
            //                             },
            //                             {
            //                                 id: 'list',
            //                                 content: [
            //                                     { id: '', content: 'Pie BOY 2' }
            //                                 ]
            //                             }
            //                         ]
            //                     }
            //                 ]
            //             }
            //         ]
            //     },
            //     {
            //         id: 'definition',
            //         content: {
            //             term: 'Pranav',
            //             definition: [
            //                 {
            //                     content: [
            //                         { id: 'bold', content: 'Pranav ' },
            //                         { id: '', content: 'is a ' },
            //                         { id: 'italics', content: 'fucking ' },
            //                         { id: 'highlight', content: 'god' }
            //                     ]
            //                 }
            //             ]
            //         }
            //     }
            // ]
        }
    },
    // paginator: { article: 'Paginator' },
    preloader: {}
};

function setPostName(state, action) {
    let newSelectedTitle = action.payload.title;
    let type = action.payload.type;
    // console.log("changing state to" + newSelectedTitle);

    return {
        ...state,
        posts: {
            ...state.posts,
            selectedTitle: newSelectedTitle,
            type: type
        }
    };
}

function content(state = initialState, action) {
    switch (action.type) {
        case 'SET_POST_TITLE':
            return setPostName(state, action);
        default:
            return state;
    }
}

export default content;
