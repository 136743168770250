import React, { Component } from 'react';
import { connect } from 'react-redux';

class HeaderSocial extends Component {
    render() {
        return (
            <ul className='header__social'>
                {this.props.data.map((links, i) => (
                    <li key={i}>
                        <a href={links.link}>
                            <i className={links.class} aria-hidden='true' />
                        </a>
                    </li>
                ))}
            </ul>
        );
    }
}

const mapStateToProps = (state, passed) => {
    return {
        data: state.content.headerSocial[passed.id]
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HeaderSocial);