import React, { Component } from 'react';
import LinkInternal from '../linkInternal';

class FeaturedEntry extends Component {
    render() {
        const {
            bgimg,
            category,
            categoryLink,
            title,
            titleLink,
            entryInfo,
            entryMeta
        } = this.props;
        let divStyle = {
            backgroundImage: 'url(' + require(`${bgimg}`) + ')'
        };
        return (
            <div className='entry' style={divStyle}>
                <div className='entry__content'>
                    <span className='entry__category'>
                        <LinkInternal to={categoryLink} value={category} />
                        {/* <a href={categoryLink}>{category}</a> */}
                    </span>
                    <h1>
                        <LinkInternal to={titleLink} value={title} />
                        {/* <a href={titleLink} title="">
                            {title}
                        </a> */}
                    </h1>
                    <div className='entry__info'>
                        <LinkInternal to={entryInfo.link} className='entry__profile-pic' value={<img
                            className='avatar'
                            src={require(`${entryInfo.img.src}`)}
                            alt={entryInfo.img.alt}
                        />} />
                        <ul className='entry__meta'>
                            <li>
                                <LinkInternal to={entryMeta.link} value={entryMeta.author} />
                            </li>
                            <li>{entryMeta.date}</li>
                        </ul>
                    </div>
                </div>
                {/* end entry__content */}
                {/* end entry */}
            </div>
        );
    }
}

export default FeaturedEntry;
