import React, { Component } from 'react';
import { connect } from 'react-redux';
import mapObjectToComponent from 'utils/mapComponent';
// import Logo from 'store/components/logo';
// import HeaderSocial from 'store/components/headerSocial';
// import Navbar from 'store/components/navbar';

class Header extends Component {
    render() {
        // const { logo, headerSocial, headerSearch, navbar } = this.props.data;
        return (
            <header className='header'>
                <div className='header__content row'>
                    {this.props.data.instances.map(mapObjectToComponent)}
                    {/* <Logo link={logo.link} img={logo.img} />
                    <HeaderSocial headerSocial={headerSocial} />
                    <a className='header__search-trigger' href='#0' />
                    <div className='header__search'>
                        <form
                            role='search'
                            method='get'
                            className='header__search-form'
                            action='#'>
                            <label>
                                <span className='hide-content'>
                                    Search for:
                                </span>
                                <input
                                    type='search'
                                    className='search-field'
                                    placeholder='Type Keywords'
                                    name='s'
                                    title='Search for:'
                                    autoComplete='off'
                                />
                            </label>
                            <input
                                type='submit'
                                className='search-submit'
                                value='Search'
                            />
                        </form>

                        <a
                            href='#0'
                            title='Close Search'
                            className='header__overlay-close'>
                            Close
                        </a>
                    </div> */}
                    {/* end header__search */}
                    {/* <a className='header__toggle-menu' href='#0' title='Menu'>
                        <span>Menu</span>
                    </a>
                    <Navbar heading={navbar.heading} nav={navbar.nav} /> */}
                </div>
            </header>
        );
    }
}

const mapStateToProps = (state, passed) => {
    return {
        data: state.content.header[passed.id]
    };
};

export default connect(mapStateToProps)(Header);
