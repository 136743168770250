import React, { Component } from 'react';
import { connect } from 'react-redux';
// import Header from 'store/components/header';
// import PageHeaderContent from 'store/components/pageHeaderContent';
import mapObjectToComponent from 'utils/mapComponent';

const handlePage = (id) => {
    if (id === 'root') {
        return 's-pageheader--home'
    } else {
        return ''
    }
}

class PageHeader extends Component {
    render() {
        // const { header, pageHeaderContent } = this.props.data;
        return (
            <section className={`s-pageheader ${handlePage(this.props.id)}`}>
                {this.props.data.instances.map(mapObjectToComponent)}
                {/* <Header
                    logo={header.logo}
                    headerSocial={header.headerSocial}
                    navbar={header.navbar}
                />
                <PageHeaderContent pageHeaderContent={pageHeaderContent} /> */}
            </section>
        );
    }
}

const mapStateToProps = (state, passed) => {
    return {
        data: state.content.pageHeader[passed.id],
        id: passed.id
    };
};

export default connect(mapStateToProps)(PageHeader);
