import React, { Component } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from 'store/actions';
// import DataLoader from 'utils/dataLoader';
import DynamicPage from 'store/pages/dynamicPage';
/* Content is the main routing component and view for the application */

class Content extends Component {
    // It should be noted that a Link (from react-router-dom) will not refresh the redux store
    // An <a> link will cause a full refresh and will affect the redux store, tests should cover both routes

    render() {
        const dynamicPageObject = this.props.dynamicPageObject;
        return (
            <div id='content'>
                    <Route
                        exact
                        path={dynamicPageObject.root.url}
                        component={() => <DynamicPage id='root' />}
                    />
                    <Route
                        exact
                        path={dynamicPageObject.blog1.url}
                        component={() => <DynamicPage id='blog1' />}
                    />
                    <Route
                        exact
                        path={dynamicPageObject.personal.url}
                        component={() => <DynamicPage id='personal' />}
                    />
                    <Route
                        exact
                        path={dynamicPageObject.work.url}
                        component={() => <DynamicPage id='work' />}
                    />
                    <Route
                        exact
                        path={dynamicPageObject.travel.url}
                        component={() => <DynamicPage id='travel' />}
                    />
                    <Route
                        exact
                        path={dynamicPageObject.technology.url}
                        component={() => <DynamicPage id='technology' />}
                    />
            </div>

        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setPostName: payload => dispatch(actions.setPostName(payload))
    };
};

const mapStateToProps = (state) => {
    return {
        dynamicPageObject: state.content.dynamicPage
    };
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Content));
