import React, { Component } from 'react';
import { connect } from 'react-redux';

class FooterSubscribe extends Component {
    render() {
        const { title, message } = this.props.data;
        return (
            <div className='col-five md-full end s-footer__subscribe'>
                <h4>{title}</h4>

                <p>{message}</p>

                <div className='subscribe-form'>
                    <form id='mc-form' className='group' noValidate={false}>
                        <input
                            type='email'
                            // value=''
                            name='EMAIL'
                            className='email'
                            id='mc-email'
                            placeholder='Email Address'
                            required=''
                        />

                        <input type='submit' name='subscribe' value='Send' />

                        <label htmlFor='mc-email' className='subscribe-message' />
                    </form>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, passed) => {
    return {
        data: state.content.footerSubscribe[passed.id]
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FooterSubscribe);
