import React, { Component } from 'react';
import { connect } from 'react-redux';
import mapObjectToComponent from 'utils/mapComponent';
import Masonry from 'react-masonry-component';

const masonryOptions = {
    transitionDuration: 0
};

class Articles extends Component {
    // entryContent = (entry, i) => {
    //     if (entry.article === 'Standard') {
    //         return <StandardBrick key={i} content={entry.content} />;
    //     } else if (entry.article === 'Video') {
    //         return <VideoBrick key={i} content={entry.content} />;
    //     } else if (entry.article === 'Quote') {
    //         return <QuoteBrick key={i} content={entry.content} />;
    //     } else if (entry.article === 'Gallery') {
    //         return <GalleryBrick key={i} content={entry.content} />;
    //     } else {
    //         return <div key={i} />;
    //     }
    // };

    render() {
        // const { entries } = this.props.data;
        return (
                <div className='row masonry-wrap'>
                    <div className='masonry'>
                        <div className='grid-sizer' />
                        <Masonry options={masonryOptions}>
                            {this.props.data.entries.map(mapObjectToComponent)}
                        </Masonry>
                    </div>
                </div>
        );
    }
}

const mapStateToProps = (state, passed) => {
    return {
        data: state.content.articles[passed.id]
    };
};

export default connect(mapStateToProps)(Articles);
