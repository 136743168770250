import React, { Component } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';

class FooterGoToTop extends Component {
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = e => {
        var pxShow = 500,
            goTopButton = $('.go-top');

        // Show or hide the button
        if ($(window).scrollTop() >= pxShow)
            goTopButton.addClass('link-is-visible');

        $(window).on('scroll', function() {
            if ($(window).scrollTop() >= pxShow) {
                if (!goTopButton.hasClass('link-is-visible'))
                    goTopButton.addClass('link-is-visible');
            } else {
                goTopButton.removeClass('link-is-visible');
            }
        });
    };

    handleClick = e => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    };

    render() {
        return (
            <div className='go-top'>
                {/* eslint-disable-next-line */}
                <div
                    className='smoothscroll'
                    title='Back To Top'
                    onClick={this.handleClick}
                />
                {/* <a className='smoothscroll' title='Back to Top' href='#top' /> */}
            </div>
        );
    }
}

const mapStateToProps = (state, passed) => {
    return {
        data: state.content.footerGoToTop[passed.id]
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FooterGoToTop);
