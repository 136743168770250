import React, { Component } from 'react';
import { connect } from 'react-redux';
import mapObjectToComponent from 'utils/mapComponent';
// import Logo from 'store/components/logo';
// import HeaderSocial from 'store/components/headerSocial';
// import Navbar from 'store/components/navbar';

class Footer extends Component {
    render() {
        // const { logo, headerSocial, headerSearch, navbar } = this.props.data;
        return (
            <footer className='s-footer' style={{background: '#19191b'}}>
                {this.props.data.instances.map(mapObjectToComponent)}
            </footer>
        );
    }
}

const mapStateToProps = (state, passed) => {
    return {
        data: state.content.footer[passed.id]
    };
};

export default connect(mapStateToProps)(Footer);
