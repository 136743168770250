import React, { Component } from 'react';
import { connect } from 'react-redux';
import mapObjectToComponent from 'utils/mapComponent';
// import Logo from 'store/components/logo';
// import HeaderSocial from 'store/components/headerSocial';
// import Navbar from 'store/components/navbar';

class FooterTop extends Component {
    render() {
        // const { logo, headerSocial, headerSearch, navbar } = this.props.data;
        return (
            <div className='s-footer__main'>
                <div className='row'>
                    {this.props.data.instances.map(mapObjectToComponent)}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, passed) => {
    return {
        data: state.content.footerTop[passed.id]
    };
};

export default connect(mapStateToProps)(FooterTop);
